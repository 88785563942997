import axios from 'axios';

export const getConf = () => {
    return axios.get('/agencies/config');
};

const exportedObject = {
    getConf
};
export default exportedObject;
