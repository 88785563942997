type CreateQueryFunction = {
    (...params: [string | number, any][]): string;
    (...params: Record<string | number, any>[]): string;
};
const createQuery: CreateQueryFunction = (...params) => {
    const query: string[] = [];
    params.forEach((param) => {
        let key: string | number, value: any;
        Array.isArray(param) ? ([key, value] = param) : ([[key, value]] = Object.entries(param));
        if ((key || key === 0) && value !== undefined && value !== null) query.push(`${key}=${value}`);
    });
    return query.join('&');
};

export { createQuery };
