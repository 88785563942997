import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sidebarShow: true,
    isPageLoading: false,
    isLayoutLoading: false,
    sidebarUnfoldable: false
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setIsPageLoading(state, { payload }) {
            state.isPageLoading = payload;
        },
        setIsLayoutLoading(state, { payload }) {
            state.isLayoutLoading = payload;
        }
    }
});

export const uiActions = uiSlice.actions;

export default uiSlice;
