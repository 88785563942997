import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';

import App from './App';
import store, { persistor } from './store';
import history from './lib/utils/history';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react';

let root = document.getElementById('root');
if (root) {
    ReactDOM.render(
        <Router history={history}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <React.StrictMode>
                        <App />
                    </React.StrictMode>
                </PersistGate>
            </Provider>
        </Router>,
        document.getElementById('root')
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
