import './scss/style.scss';

import Locales from './lib/utils/locales/Locales';
import Snackbar from './mantis/components/@extended/Snackbar';
import RTLLayout from './mantis/components/RTLLayout';
import ScrollTop from './mantis/components/ScrollTop';
import ThemeCustomization from './mantis/themes';

import { QueryClient, QueryClientProvider } from 'react-query';
import Routes from 'routes';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import api from './lib/api-client';
import { authActions } from './store/auth';
import { fetchUserData } from './store/auth/auth-actions';

const queryClient = new QueryClient();

function App() {
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.auth ?? {});

    const fetchProfile = async () => {
        const profileData = await dispatch(fetchUserData());
        dispatch(authActions.setUser(profileData.payload));
    };

    useEffect(() => {
        api.init(dispatch);
    }, []);

    useEffect(() => {
        if (token) {
            fetchProfile().catch(console.error);
        }
    }, [token]);

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeCustomization>
                <RTLLayout>
                    <Locales>
                        <ScrollTop>
                            <Routes />
                            <Snackbar />
                        </ScrollTop>
                    </Locales>
                </RTLLayout>
            </ThemeCustomization>
        </QueryClientProvider>
    );
}

export default App;
