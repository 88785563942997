import PropTypes from 'prop-types';
import { ReactNode, useMemo } from 'react';

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';

// project import
import useConfig from '../hooks/useConfig';
import Palette from './palette';
import Typography from './typography';
import CustomShadows from './shadows';
import mantisComponentsOverride from './overrides';
import componentsOverride from 'lib/ui';
import { FontFamily } from '../types/config';
import { merge } from 'lodash';

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

type ThemeCustomizationProps = {
    children: ReactNode;
};

export default function ThemeCustomization({ children }: ThemeCustomizationProps) {
    const { themeDirection, mode, presetColor, fontFamily, fontSizeMultiplier } = useConfig();

    const theme = useMemo(() => Palette(mode, presetColor), [mode, presetColor]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const themeTypography = useMemo(
        () => Typography(fontFamily as FontFamily, theme, fontSizeMultiplier),
        [fontFamily, theme, fontSizeMultiplier]
    );
    const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

    const themeOptions = useMemo(
        () => ({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 768,
                    md: 1024,
                    lg: 1266,
                    xl: 1536
                }
            },
            direction: themeDirection,
            mixins: {
                toolbar: {
                    minHeight: 60,
                    paddingTop: 8,
                    paddingBottom: 8
                }
            },
            palette: theme.palette,
            customShadows: themeCustomShadows,
            typography: themeTypography
        }),
        [themeDirection, theme, themeTypography, themeCustomShadows]
    );

    const themes: Theme = createTheme(themeOptions);
    themes.components = merge(mantisComponentsOverride(themes), componentsOverride(themes));

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

ThemeCustomization.propTypes = {
    children: PropTypes.node
};
