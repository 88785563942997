import axios from 'axios';

type ExportFormat = 'pdf';

export const quote = async (quoteId: string, format: ExportFormat) => {
    return axios.get(`/export/quote?quoteId=${quoteId}&format=${format}`, {
        responseType: 'blob'
    });
};

export const quotes = async (quoteIds: string, format: ExportFormat, withTotals: boolean) => {
    return axios.post(
        `/export/quotes?format=${format}&withTotals=${withTotals}`,
        { quoteIds },
        {
            responseType: 'blob'
        }
    );
};

export const quotesSummary = async (leadId: string, quoteIds: number[], withQuoteNotes: boolean, format: string) => {
    return axios.post(
        `/export/lead-quotes-summary?format=${format}&withQuotesNotes=${withQuoteNotes}`,
        { leadId, quoteIds },
        {
            responseType: 'blob'
        }
    );
};

export const getContract = (ingestionMetadataId: string) => {
    return axios.get(`/export/contracts/ingestions/${ingestionMetadataId}`, {
        responseType: 'blob'
    });
};

const exportedObject = {
    quote,
    quotes,
    quotesSummary,
    getContract
};
export default exportedObject;
