import axios from 'axios';

export const castContainerInfosToAPI = (containersInfo) => {
    return containersInfo.map((containerInfo) => ({
        containerTypeShortcut: containerInfo.fcl_container_type_shortcut,
        containerTypeId: containerInfo.fcl_container_type_id,
        containerSizeId: containerInfo.fcl_container_size_id,
        containerSizeFt: containerInfo.fcl_container_size_ft,
        amount: containerInfo.fcl_container_amount,
        isOpenTop: containerInfo.is_open_top,
        isHazardous: containerInfo.is_hazardous,
        isOverweight: containerInfo.is_overweight
    }));
};

export const castBoxInfosToAPI = (boxesInfo) => {
    return boxesInfo.map((boxInfo) => ({
        weightKg: boxInfo.lcl_weight_kg,
        volumeM3: boxInfo.lcl_volume_cbm,
        volumetricWeightKg: boxInfo.lcl_volumetric_weight_kg,
        isStackable: boxInfo.lcl_is_stackable,
        lengthCm: boxInfo.lcl_dimension_length_cm,
        widthCm: boxInfo.lcl_dimension_width_cm,
        heightCm: boxInfo.lcl_dimension_height_cm,
        amount: boxInfo.lcl_amount,
        isHazardous: boxInfo.is_hazardous
    }));
};

export const getFCLMainRates = async (
    pol,
    pod,
    containersInfo,
    validUntilDate,
    incotermId,
    withMetadata = false,
    withPickup = true,
    withProfit = false,
    withExpiredContracts = false
) => {
    return axios.post(
        `/rates/fcl/main?withMetadata=${withMetadata}&withPickup=${withPickup}&withProfit=${withProfit}&withExpired=${withExpiredContracts}`,
        {
            pol: pol,
            pod: pod,
            validUntilDate: validUntilDate,
            containersInfo: castContainerInfosToAPI(containersInfo),
            incotermId
        }
    );
};

export const getFCLOriginRates = async ({
    pol,
    pod,
    containersInfo,
    validUntilDate,
    srcGeo,
    billsOfLadingAmt,
    mainChargesPriceUSD,
    frtChargesPriceUSD,
    withPickup = true,
    withProfit = false,
    mainContractRevisionId,
    incotermId,
    withExpiredContracts = false
}) => {
    return axios.post(`/rates/fcl/origin?withPickup=${withPickup}&withProfit=${withProfit}&withExpired=${withExpiredContracts}`, {
        pol: pol,
        pod: pod,
        billsOfLadingAmt,
        srcGeo,
        validUntilDate,
        containersInfo: castContainerInfosToAPI(containersInfo),
        mainChargesPriceUSD,
        frtChargesPriceUSD,
        mainContractRevisionId,
        incotermId
    });
};

export const getFCLDestinationRates = async ({
    pol,
    pod,
    containersInfo,
    validUntilDate,
    dstGeo,
    billsOfLadingAmt,
    mainChargesPriceUSD,
    frtChargesPriceUSD,
    withPickup = true,
    withProfit = false,
    withExpiredContracts = false,
    mainContractRevisionId,
    incotermId
}) => {
    return await axios.post(
        `/rates/fcl/destination?withPickup=${withPickup}&withProfit=${withProfit}&withExpired=${withExpiredContracts}`,
        {
            pol: pol,
            pod: pod,
            billsOfLadingAmt,
            dstGeo,
            validUntilDate,
            containersInfo: castContainerInfosToAPI(containersInfo),
            mainChargesPriceUSD,
            frtChargesPriceUSD,
            mainContractRevisionId,
            incotermId
        }
    );
};

export const getLCLMainRates = async (
    pol,
    pod,
    boxesInfo,
    validUntilDate,
    incotermId,
    withMetadata = false,
    withPickup = true,
    withProfit = false,
    withExpiredContracts = false
) => {
    return axios.post(
        `/rates/lcl/main?withMetadata=${withMetadata}&withPickup=${withPickup}&withProfit=${withProfit}&withExpired=${withExpiredContracts}`,
        {
            pol,
            pod,
            validUntilDate,
            boxesInfo: castBoxInfosToAPI(boxesInfo),
            incotermId
        }
    );
};

export const getLCLOriginRates = async ({
    pol,
    pod,
    boxesInfo,
    validUntilDate,
    srcGeo,
    billsOfLadingAmt,
    mainChargesPriceUSD,
    frtChargesPriceUSD,
    withPickup = true,
    withProfit = false,
    withExpiredContracts = false,
    mainContractRevisionId,
    incotermId
}) => {
    return axios.post(`/rates/lcl/origin?withPickup=${withPickup}&withProfit=${withProfit}&withExpired=${withExpiredContracts}`, {
        pol,
        pod,
        billsOfLadingAmt,
        srcGeo,
        validUntilDate,
        mainChargesPriceUSD,
        frtChargesPriceUSD,
        boxesInfo: castBoxInfosToAPI(boxesInfo),
        mainContractRevisionId,
        incotermId
    });
};

export const getLCLDestinationRates = async ({
    pol,
    pod,
    boxesInfo,
    validUntilDate,
    dstGeo,
    billsOfLadingAmt,
    mainChargesPriceUSD,
    frtChargesPriceUSD,
    withPickup = true,
    withProfit = false,
    withExpiredContracts = false,
    mainContractRevisionId,
    incotermId
}) => {
    return await axios.post(
        `/rates/lcl/destination?withPickup=${withPickup}&withProfit=${withProfit}&withExpired=${withExpiredContracts}`,
        {
            pol,
            pod,
            billsOfLadingAmt,
            dstGeo,
            validUntilDate,
            mainChargesPriceUSD,
            frtChargesPriceUSD,
            boxesInfo: castBoxInfosToAPI(boxesInfo),
            mainContractRevisionId,
            incotermId
        }
    );
};

export const getCustomRate = async ({
    fees,
    containersInfo = null,
    boxesInfo = null,
    mainChargesPriceUSD = 0,
    frtChargesPriceUSD = 0,
    primageChargesPriceUSD = 0,
    contractTotalPriceUSD = 0,
    contractRevisionIdTotals = {},
    billsOfLading = 1,
    transformBoxes = false,
    transformContainers = false,
    combineCharges = false
}) => {
    let url = `/rates/custom`;
    if (combineCharges === true) {
        url += '?combineCharges=true';
    }
    return axios.post(url, {
        fees,
        containersInfo: transformContainers && containersInfo ? castContainerInfosToAPI(containersInfo) : containersInfo,
        boxesInfo: transformBoxes && boxesInfo ? castBoxInfosToAPI(boxesInfo) : boxesInfo,
        mainChargesPriceUSD,
        frtChargesPriceUSD,
        primageChargesPriceUSD,
        contractTotalPriceUSD,
        contractRevisionIdTotals,
        billsOfLading
    });
};

export const getPresaleMainRates = ({ loadTypeId, date, pol, pod, loads, incotermId }) => {
    return axios.post('/rates/presale/main', {
        loadTypeId,
        date,
        pol,
        pod,
        loads,
        incotermId
    });
};

export const getPresaleLandRates = ({ loadTypeId, date, fromPlace, toPlace, loads, incotermId }) => {
    return axios.post('/rates/presale/land', {
        loadTypeId,
        date,
        fromPlace,
        toPlace,
        loads,
        incotermId
    });
};

const exportedObject = {
    getCustomRate,
    getFCLMainRates,
    getLCLMainRates,
    getFCLOriginRates,
    getLCLOriginRates,
    getPresaleLandRates,
    getPresaleMainRates,
    getFCLDestinationRates,
    getLCLDestinationRates
};

export default exportedObject;
