import axios from 'axios';

const getCurrencies = async (exchangeCurrencyId) => {
    const query = exchangeCurrencyId ? `?withExchangeCurrencyId=${exchangeCurrencyId}` : '';
    return await axios.get(`/currencies${query}`);
};

const exchangeRate = async (from_id, to_id) => {
    const query = from_id && to_id ? `?from_id=${from_id}&to_id=${to_id}` : '';
    return await axios.get(`/currencies/exchange${query}`);
};

const exportedObject = {
    exchangeRate,
    getCurrencies
};
export default exportedObject;
