// third-party
import { FormattedMessage } from 'react-intl';

// assets
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import { permissions } from 'common/permissions-consts';
import { MenuItem } from './interface';

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

export const CUSTOMERS_ID = 'customers';

export const navPermissions = {
    [CUSTOMERS_ID]: [permissions.crm.read, permissions.crm.admin]
};

const applications: MenuItem = {
    id: 'crm',
    type: 'group',
    title: <FormattedMessage id="crm" />,
    children: [
        {
            id: CUSTOMERS_ID,
            title: <FormattedMessage id="customers" />,
            type: 'item',
            url: '/crm/customers',
            icon: ContactsOutlinedIcon,
            requiredPermission: navPermissions?.[CUSTOMERS_ID],
            requiredFeatureFlag: 'crm.customers'
        }
    ]
};

export default applications;
