import axios from 'axios';

export const getCountries = async () => {
    return await axios.get(`/places/countries`);
};

export const getCities = async (countryId) => {
    let query = '';
    if (countryId) {
        query = `?countryId=${countryId}`;
    }
    return await axios.get(`/places/cities${query}`);
};

const getPlacesUrl = (isRanked, text, shippingType, loadTypeId, validityDate) => {
    let url = `/places?text=${text}&shippingType=${shippingType}`;
    if (isRanked) {
        url += '&ranked=1';
    }
    if (loadTypeId) {
        url += `&loadTypeId=${loadTypeId}`;
    }
    if (validityDate) {
        url += `&validityDate=${validityDate}`;
    }
    return url;
};

export const getPlaces = async ({ text, shippingType }) => {
    return await axios.get(getPlacesUrl(false, text, shippingType));
};

export const getPorts = async ({ loadTypeId, shippingTypeId, text, isActiveOrigin, isActiveDestination }) => {
    let url = `/places/query`;
    const params = [];
    if (text) {
        params.push(`text=${text}`);
    }
    if (isActiveOrigin) {
        params.push(`isOrigin=${isActiveOrigin}`);
    }
    if (isActiveDestination) {
        params.push(`isDestination=${isActiveDestination}`);
    }
    if (loadTypeId) {
        params.push(`loadTypeId=${loadTypeId}`);
    }
    if (shippingTypeId) {
        params.push(`shippingTypeId=${shippingTypeId}`);
    }
    if (params.length > 0) {
        url += `?${params.join('&')}`;
    }
    return axios.get(url);
};

export const getPlacesRanked = async ({ text, portType, loadTypeId, validityDate }) => {
    return await axios.get(getPlacesUrl(true, text, portType, loadTypeId, validityDate));
};

export const getActivePorts = async ({ validityDate, loadTypeId, shippingTypeId, side }) => {
    const params = [];
    params.push(`date=${validityDate}`);
    params.push(`shippingTypeId=${shippingTypeId}`);
    params.push(`loadTypeId=${loadTypeId}`);
    if (side) {
        params.push(`side=${side.toLowerCase()}`);
    }
    return axios.get(`/places/active?${params.join('&')}`);
};

const exportedObject = {
    getPorts,
    getPlaces,
    getCities,
    getCountries,
    getActivePorts,
    getPlacesRanked
};
export default exportedObject;
