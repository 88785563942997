// third-party
import { FormattedMessage } from 'react-intl';

// assets
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import WidthFullOutlinedIcon from '@mui/icons-material/WidthFullOutlined';
import { permissions } from 'common/permissions-consts';
import { MenuItem } from './interface';

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

export const FCL_EXPLORER_ID = 'fcl-archive';
export const LCL_EXPLORER_ID = 'lcl-archive';

export const navPermissions = {
    [FCL_EXPLORER_ID]: [permissions.quotes.read, permissions.quotes.admin, permissions.quotes.admin_self],
    [LCL_EXPLORER_ID]: [permissions.quotes.read, permissions.quotes.admin, permissions.quotes.admin_self]
};

const applications: MenuItem = {
    id: 'archive',
    type: 'group',
    title: <FormattedMessage id="archive" />,
    children: [
        {
            id: FCL_EXPLORER_ID,
            title: <FormattedMessage id="fcl-archive" />,
            type: 'item',
            url: '/archive/fcl',
            icon: WidthFullOutlinedIcon,
            requiredPermission: navPermissions?.[FCL_EXPLORER_ID],
            requiredFeatureFlag: 'explorer.fcl'
        },
        {
            id: LCL_EXPLORER_ID,
            title: <FormattedMessage id="lcl-archive" />,
            type: 'item',
            url: '/archive/lcl',
            icon: Inventory2OutlinedIcon,
            requiredPermission: navPermissions?.[LCL_EXPLORER_ID],
            requiredFeatureFlag: 'explorer.lcl'
        }
    ]
};

export default applications;
