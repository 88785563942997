import axios from 'axios';

const getSettings = async (withExchangeCurrencyId) => {
    try {
        let url = '/settings';
        if (withExchangeCurrencyId) {
            url += `?withExchangeCurrencyId=${withExchangeCurrencyId}`;
        }
        return axios.get(url);
    } catch (error) {
        console.error(error);
    }
};

const exportedObject = { getSettings };
export default exportedObject;
