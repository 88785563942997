// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DashboardOutlined, UserOutlined } from '@ant-design/icons';
import RuleIcon from '@mui/icons-material/Rule';
import { permissions } from 'common/permissions-consts';
import { MenuItem } from './interface';

// icons
const icons = {
    DashboardOutlined,
    UserOutlined,
    RuleIcon
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

export const DASHBOARD_ID = 'dashboard';
export const AGENCY_ID = 'agency';
export const USERS_ID = 'users';

export const navPermissions = {
    [DASHBOARD_ID]: [permissions.dashboard.self, permissions.dashboard.admin],
    [AGENCY_ID]: [permissions.agencies.read, permissions.agencies.admin],
    [USERS_ID]: [permissions.users.read, permissions.users.admin]
};

const applications: MenuItem = {
    id: 'general',
    type: 'group',
    title: <FormattedMessage id="general" />,
    children: [
        {
            id: DASHBOARD_ID,
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.DashboardOutlined,
            requiredPermission: navPermissions?.[DASHBOARD_ID],
            requiredFeatureFlag: 'personalDashboard'
        },
        {
            id: USERS_ID,
            title: <FormattedMessage id="users" />,
            type: 'item',
            url: '/users',
            icon: icons.UserOutlined,
            requiredPermission: navPermissions?.[USERS_ID],
            requiredFeatureFlag: 'agencyUsers'
        }
    ]
};

export default applications;
