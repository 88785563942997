import { DefaultConfigProps, MenuOrientation, ThemeDirection, ThemeMode } from './mantis/types/config';

export const drawerWidth = 200;

// ==============================|| THEME CONFIG  ||============================== //

export type CustomDefaultConfigProps = DefaultConfigProps & { defaultPath: string; fontSizeMultiplier: number };

const config: CustomDefaultConfigProps = {
    defaultPath: '/leads',
    fontFamily: `'Public Sans', sans-serif`,
    i18n: 'en',
    menuOrientation: MenuOrientation.VERTICAL,
    miniDrawer: false,
    container: true,
    mode: ThemeMode.LIGHT,
    presetColor: 'default',
    themeDirection: ThemeDirection.LTR,
    fontSizeMultiplier: 0.857
};
export default config;
