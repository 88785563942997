import quoteApps from './quotes';
import generalApps from './general';
import explorerApps from './explorer';
import crmApps from './crm';
import pricelistApps from './pricelists';
import { MenuItem } from './interface';

type MenuItems = {
    items: MenuItem[];
};

export const menuItems: MenuItems = { items: [quoteApps, pricelistApps, crmApps, generalApps, explorerApps] };

export default menuItems;
