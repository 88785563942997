import React, { useEffect, useState } from 'react';

enum Types {
    Map,
    Set,
    Regular
}

interface IStoredObject<T> {
    type: Types;
    value: T;
}

export const LOCALSTORAGE_PREFIX = 'pierate-storage';

export const getLocalStorageKey = (key: string) => `${LOCALSTORAGE_PREFIX}-${key}`;

const getValue = <T,>(key: string, initialValue: T): T => {
    if (typeof window === 'undefined') return initialValue;
    const storedObj = localStorage.getItem(getLocalStorageKey(key));
    if (storedObj) {
        const parsedStoredObj: IStoredObject<T> = JSON.parse(storedObj);
        if (parsedStoredObj.type === Types.Map) return new Map(parsedStoredObj.value as any) as T;
        if (parsedStoredObj.type === Types.Set) return new Set(parsedStoredObj.value as any) as T;
        return parsedStoredObj.value;
    }

    return initialValue;
};

const setValue = <T,>(key: string, value: T): void => {
    let storedObj: IStoredObject<T> = { type: Types.Regular, value: value };
    if (value instanceof Map) storedObj = { type: Types.Map, value: Array.from((value as Map<any, any>).entries()) as T };
    if (value instanceof Set) storedObj = { type: Types.Set, value: Array.from(value as Set<any>) as T };
    localStorage.setItem(getLocalStorageKey(key), JSON.stringify(storedObj));
};

const useLocalStorage = <T,>(key: string, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
    const [localStorageValue, setLocalStorageValue] = useState<T>(() => getValue(key, initialValue));

    useEffect(() => {
        setValue(key, localStorageValue);
    }, [key, localStorageValue]);

    return [localStorageValue, setLocalStorageValue];
};

export default useLocalStorage;
