// ==============================|| OVERRIDES - DIALOG TITLE ||============================== //

import useConfig from 'mantis/hooks/useConfig';

export default function DialogTitle() {
    const { fontSizeMultiplier } = useConfig();

    return {
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: `${1 * fontSizeMultiplier}rem`,
                    fontWeight: 500
                }
            }
        }
    };
}
