import axios from 'axios';

export const login = async (userData) => {
    return await axios.post('/auth/login', {
        email: userData.email,
        password: userData.password
    });
};

export const fetchUser = async () => {
    return await axios.get(`/users/profile`);
};

export const validateMSLogin = async (account) => {
    return await axios.post(`/auth/login/social`, {
        email: account?.userName,
        fullName: account?.name
    });
};

const exportedObject = {
    login,
    fetchUser,
    validateMSLogin
};
export default exportedObject;
