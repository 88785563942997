const createLocalStorageKey = (page: string) => {
    return (key: string) => `${page}-${key}`;
};

const getLeadsKey = createLocalStorageKey('leads');
const getQuotessKey = createLocalStorageKey('quotes');

export const LEADS: Record<string, string> = {
    MAIN_FILTERS_SETTINGS: getLeadsKey('main-filters-settings')
};

export const QUOTES: Record<string, string> = {
    MAIN_FILTERS_SETTINGS: getQuotessKey('main-filters-settings')
};

const commonLocalStorage = {
    LEADS,
    QUOTES
};
export default commonLocalStorage;
