// ==============================|| OVERRIDES - DIALOG CONTENT TEXT ||============================== //

import useConfig from 'mantis/hooks/useConfig';

export default function DialogContentText(theme) {
    const { fontSizeMultiplier } = useConfig();

    return {
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    fontSize: `${0.875 * fontSizeMultiplier}rem`,
                    color: theme.palette.text.primary
                }
            }
        }
    };
}
