// ==============================|| OVERRIDES - ICON BUTTON ||============================== //

import useConfig from 'mantis/hooks/useConfig';

export default function IconButton(theme) {
    const { fontSizeMultiplier } = useConfig();

    return {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: 4
                },
                sizeLarge: {
                    width: theme.spacing(5.5),
                    height: theme.spacing(5.5),
                    fontSize: `${1.25 * fontSizeMultiplier}rem`
                },
                sizeMedium: {
                    width: theme.spacing(4.5),
                    height: theme.spacing(4.5),
                    fontSize: `${1 * fontSizeMultiplier}rem`
                },
                sizeSmall: {
                    width: theme.spacing(3.75),
                    height: theme.spacing(3.75),
                    fontSize: `${0.75 * fontSizeMultiplier}rem`
                }
            }
        }
    };
}
