export const permissions: Record<string, Record<string, number>> = {
    agencies: {
        read: 4, // USERS_READ
        admin: 5 // USERS_ADMIN
    },
    users: {
        read: 4, // USERS_READ
        admin: 5 // USERS_ADMIN
    },
    quotes: {
        read: 1, // QUOTES_READ
        admin: 3, // QUOTES_ADMIN
        admin_self: 2 // QUOTES_ADMIN_SELF
    },
    dashboard: {
        self: 6, // DASHBOARD_SELF
        admin: 7 // DASHBOARD_MANAGEMENT
    },
    data: {
        read: 8, // DATA_SOURCES_READ
        admin: 9 // DATA_SOURCES_ADMIN
    },
    leads: {
        read: 10, // LEADS_READ
        admin_self: 12, // LEADS_ADMIN_SELF
        admin: 11 // LEADS_ADMIN
    },
    crm: {
        read: 13,
        admin: 14
    }
};

export default { permissions };
