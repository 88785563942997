import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    quote: {}
};

const draftEditSlice = createSlice({
    name: 'drafts-quote-edit',
    initialState,
    reducers: {
        setQuote(state, { payload }) {
            state.quote = { ...payload };
        },
        clear(state) {
            state.quote = initialState.quote;
        }
    }
});

export const draftEditActions = draftEditSlice.actions;

export default draftEditSlice;
