// ==============================|| OVERRIDES - INPUT ADORNMENT ||============================== //

import useConfig from 'mantis/hooks/useConfig';

export default function InputAdornment() {
    const { fontSizeMultiplier } = useConfig();

    return {
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    '& .MuiSvgIcon-root': {
                        fontSize: `${1.25 * fontSizeMultiplier}rem`
                    }
                }
            }
        }
    };
}
