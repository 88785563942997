// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { PostAdd, PendingActions, RequestQuote } from '@mui/icons-material';
import { permissions } from 'common/permissions-consts';
import { MenuItem } from './interface';
import LeadIcon from '../../Icons/LeadIcon';

// icons
const icons = {
    PostAdd,
    LeadIcon,
    PendingActions,
    RequestQuote
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

export const MANAGE_LEADS_ID = 'leads-manage';
export const MANAGE_QUOTES_ID = 'quotes-manage';
export const MANAGE_DRAFTS_ID = 'drafts-manage';
export const CREATE_QUOTE_ID = 'quote-create';

export const navPermissions = {
    [MANAGE_LEADS_ID]: [permissions.leads.read, permissions.leads.admin_self, permissions.leads.admin],
    [MANAGE_QUOTES_ID]: [permissions.quotes.read, permissions.quotes.admin_self, permissions.quotes.admin],
    [MANAGE_DRAFTS_ID]: [permissions.quotes.read, permissions.quotes.admin_self, permissions.quotes.admin],
    [CREATE_QUOTE_ID]: [permissions.quotes.admin_self, permissions.quotes.admin]
};

const applications: MenuItem = {
    id: 'quotes',
    type: 'group',
    title: <FormattedMessage id="quotes" />,
    children: [
        {
            id: MANAGE_LEADS_ID,
            title: <FormattedMessage id="manage-leads" />,
            type: 'item',
            url: '/leads',
            icon: icons.LeadIcon,
            requiredPermission: navPermissions?.[MANAGE_LEADS_ID],
            requiredFeatureFlag: 'manageLeads'
        },
        {
            id: MANAGE_QUOTES_ID,
            title: <FormattedMessage id="manage-quote" />,
            type: 'item',
            url: '/quotes',
            icon: icons.RequestQuote,
            requiredPermission: navPermissions?.[MANAGE_QUOTES_ID],
            requiredFeatureFlag: 'manageQuotes'
        },
        {
            id: MANAGE_DRAFTS_ID,
            title: <FormattedMessage id="manage-drafts" />,
            type: 'item',
            url: '/quotes/drafts',
            icon: icons.PendingActions,
            requiredPermission: navPermissions?.[MANAGE_DRAFTS_ID],
            requiredFeatureFlag: 'manageDrafts'
        },
        {
            id: CREATE_QUOTE_ID,
            title: <FormattedMessage id="create-quote" />,
            type: 'item',
            url: '/quotes/create',
            icon: icons.PostAdd,
            requiredPermission: navPermissions?.[CREATE_QUOTE_ID],
            requiredFeatureFlag: 'createQuote'
        }
    ]
};

export default applications;
