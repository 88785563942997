import axios from 'axios';

export const getByUserId = async (agencyId, userId) => {
    return await axios.get(`/quotes?userId=${userId}`);
};

export const getAgencyQuotes = async (options) => {
    const query = [`isDraft=${Boolean(options?.isDraft)}`];
    options?.loadTypeId && query.push(`loadTypeId=${options?.loadTypeId}`);
    options?.enrichLeadName && query.push(`enrichLeadName=${Boolean(options.enrichLeadName)}`);
    options?.withClosed === false && query.push(`withClosed=${Boolean(options.withClosed)}`);

    return await axios.get(`/quotes?${query.join('&')}`);
};

export const getQuote = async (quoteId) => {
    return await axios.get(`/quotes/${quoteId}`);
};

export const getStatusesWithReasons = async () => {
    return await axios.get(`/quotes/statuses-with-reasons`);
};

export const setQuoteStatus = async (quoteId, status) => {
    return await axios.patch(`/quotes/${quoteId}`, {
        status: status
    });
};

export const patchQuote = async (
    quoteId,
    { rates, currency, fullPriceUSD, fullPrice, notes, shippingCompanies, name, details, userId, freeDemurrageDays, customerOverrides }
) => {
    return await axios.patch(`/quotes/${quoteId}`, {
        rates: rates,
        price:
            fullPriceUSD >= 0
                ? {
                      price: fullPrice,
                      usd: fullPriceUSD,
                      currency
                  }
                : undefined,
        shippingCompanies,
        notes,
        name,
        details,
        userId,
        freeDemurrageDays,
        customerOverrides
    });
};

export const setQuoteIsNotDraft = async (quoteId) => {
    return await axios.patch(`/quotes/${quoteId}`, {
        isDraft: false
    });
};

export const upsertQuote = async (quote) => {
    return await axios.post(`/quotes`, {
        quote
    });
};

export const downloadQuote = async (quoteId, format) => {
    return axios.get(`/export/quote?quoteId=${quoteId}&format=${format}`, {
        responseType: 'blob'
    });
};

export const deleteQuote = async (quoteId) => {
    return axios.delete(`/quotes/${quoteId}`);
};

export const getSimilarQuotes = async (details, customerId, loadTypeId, limit) => {
    return axios.post(`/quotes/similar`, {
        details,
        customerId,
        loadTypeId,
        limit
    });
};

export const getQuoteHistory = async (customerId, quotesLimit) => {
    const query = [`customerId=${customerId}`];
    if (quotesLimit) {
        query.push(`quotesLimit=${quotesLimit}`);
    }
    return await axios.get(`/quotes/history?${query.join('&')}`);
};

export const duplicate = async (quoteIds, quoteOverrides) => {
    return axios.post(`/quotes/duplicate`, {
        quoteIds,
        quoteOverrides
    });
};

const exportedObject = {
    getQuote,
    duplicate,
    patchQuote,
    getByUserId,
    upsertQuote,
    deleteQuote,
    downloadQuote,
    setQuoteStatus,
    getAgencyQuotes,
    getSimilarQuotes,
    setQuoteIsNotDraft,
    getStatusesWithReasons,
    getQuoteHistory
};
export default exportedObject;
