import { lazy } from 'react';

// project import
import CommonLayout from 'components/Layout/CommonLayout';
import Loadable from 'mantis/components/Loadable';

// render - login
const LoginPage = Loadable(lazy(() => import('pages/login')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/login',
    element: <CommonLayout />,
    children: [
        {
            path: '',
            element: <LoginPage />
        }
    ]
};

export default LoginRoutes;
