import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    customer: {},
    quote: {}
};

const quoteSlice = createSlice({
    name: 'quote',
    initialState,
    reducers: {
        setCustomer(state, { payload }) {
            state.customer = payload;
        },
        setQuote(state, { payload }) {
            state.quote = { ...payload };
        }
    }
});

export const quoteActions = quoteSlice.actions;

export default quoteSlice;
