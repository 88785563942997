import { lazy } from 'react';

// project import
const MainLayout = Loadable(lazy(() => import('components/Layout/MainLayout')));
const AuthGuard = Loadable(lazy(() => import('lib/router/AuthGuard')));
const GlobalMainRoutesContexts = Loadable(lazy(() => import('store/contexts/GlobalMainRoutesContexts')));
import Loadable from 'mantis/components/Loadable';
import { Navigate } from 'react-router';

// render - dashboard
const DashboardPage = Loadable(lazy(() => import('pages/dashboard')));

// render - agency
const AgencyPage = Loadable(lazy(() => import('pages/agencies')));

// render - users
const UsersPage = Loadable(lazy(() => import('pages/users')));
const UserEditPage = Loadable(lazy(() => import('pages/users/edit')));

// render - quotes
const Quotes = Loadable(lazy(() => import('pages/quotes')));
const Leads = Loadable(lazy(() => import('pages/leads')));
const LeadPage = Loadable(lazy(() => import('pages/leads/lead')));
const QuoteDrafts = Loadable(lazy(() => import('pages/quotes/drafts')));
const NewQuote = Loadable(lazy(() => import('pages/quotes/create/NewQuote')));
const NewQuoteFromId = Loadable(lazy(() => import('pages/quotes/create/NewQuoteFromId')));
const QuotePage = Loadable(lazy(() => import('pages/quotes/quote')));
const CrmCustomers = Loadable(lazy(() => import('../pages/crm/customers')));
const PricelistStatus = Loadable(lazy(() => import('../pages/suppliers/pricelists/status')));

import { Outlet } from 'react-router-dom';

// render - costs
const Costs = Loadable(lazy(() => import('../pages/suppliers/costs')));

// render - explore
const Archive = Loadable(lazy(() => import('pages/archive/index')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: (
                <AuthGuard>
                    <GlobalMainRoutesContexts>
                        <Outlet />
                    </GlobalMainRoutesContexts>
                </AuthGuard>
            ),
            children: [
                {
                    path: '/',
                    element: <MainLayout isFitToWindow={true} maxWidth={false} />,
                    children: [
                        {
                            path: '',
                            element: <Navigate to="/leads" />
                        },
                        {
                            path: 'leads',
                            element: <Leads />
                        },
                        {
                            path: 'quotes',
                            element: <Quotes />
                        }
                    ]
                },
                {
                    path: '/',
                    element: <MainLayout maxWidth={'xl'} />,
                    children: [
                        {
                            path: 'dashboard',
                            element: <DashboardPage />
                        },
                        {
                            path: 'agency',
                            element: <AgencyPage />
                        },
                        {
                            path: 'users',
                            children: [
                                {
                                    path: '/users',
                                    element: <UsersPage />
                                },
                                {
                                    path: ':userId',
                                    element: <UserEditPage />
                                }
                            ]
                        },
                        {
                            path: 'costs',
                            children: [
                                {
                                    path: 'fcl/freight',
                                    element: <Costs />
                                },
                                {
                                    path: 'lcl/freight',
                                    element: <Costs />
                                },
                                {
                                    path: 'fcl/land',
                                    element: <Costs />
                                },
                                {
                                    path: 'lcl/land',
                                    element: <Costs />
                                }
                            ]
                        },
                        {
                            path: 'pricelists',
                            children: [
                                {
                                    path: 'status',
                                    element: <PricelistStatus />
                                }
                            ]
                        },
                        {
                            path: 'archive',
                            children: [
                                {
                                    path: 'lcl',
                                    element: <Archive />
                                },
                                {
                                    path: 'fcl',
                                    element: <Archive />
                                }
                            ]
                        },
                        {
                            path: 'leads',
                            children: [
                                {
                                    path: ':leadId',
                                    element: <LeadPage />
                                }
                            ]
                        },
                        {
                            path: 'quotes',
                            children: [
                                {
                                    path: 'drafts',
                                    element: <QuoteDrafts />
                                },
                                {
                                    path: 'create/:quoteId',
                                    element: <NewQuoteFromId />
                                },
                                {
                                    path: 'create',
                                    element: <NewQuote />
                                },
                                {
                                    path: ':quoteId',
                                    element: <QuotePage />
                                }
                            ]
                        },
                        {
                            path: 'crm',
                            children: [
                                {
                                    path: 'customers',
                                    element: <CrmCustomers />
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
};

export default MainRoutes;
