import axios from 'axios';

const buildParamsArr = (withQuotes, withEntities, aggEntities, withClosed) => {
    const paramsArr = [];
    withQuotes && paramsArr.push('withQuotes=true');
    withEntities && paramsArr.push('withEntities=true');
    aggEntities && paramsArr.push('aggEntities=true');
    withClosed === false && paramsArr.push('withClosed=false');
    return paramsArr;
};
const getLeadQuery = (withQuotes, withEntities, aggEntities, withClosed) => {
    const paramsArr = buildParamsArr(withQuotes, withEntities, aggEntities, withClosed);
    return paramsArr.length > 0 ? `?${paramsArr.join('&')}` : '';
};

const getLead = async (leadId, withQuotes, withEntities, aggEntities = false) => {
    const query = getLeadQuery(withQuotes, withEntities, aggEntities);
    return axios.get(`/leads/${leadId}${query}`);
};

const getLeads = async (withQuotes, withEntities, aggEntities = false, withClosed = true) => {
    const query = getLeadQuery(withQuotes, withEntities, aggEntities, withClosed);
    return axios.get(`/leads${query}`);
};

const getActiveLeads = (withQuotes, withEntities, aggEntities, withClosed) => {
    const query = getLeadQuery(withQuotes, withEntities, aggEntities, withClosed);
    return axios.get(`/leads/active${query}`);
};

const deleteLead = async (leadId) => {
    return axios.delete(`/leads/${leadId}`);
};

const patchLead = async (leadId, data) => {
    return axios.patch(`/leads/${leadId}`, data);
};

const createLead = async () => {
    return axios.post(`/leads`);
};

const duplicateLead = async (leadId, quoteIds, leadOverrides, quoteOverrides) => {
    return axios.post(`/leads/duplicate`, {
        leadId,
        quoteIds,
        leadOverrides,
        quoteOverrides
    });
};

const exportedObject = {
    getLead,
    getLeads,
    patchLead,
    createLead,
    deleteLead,
    duplicateLead,
    getActiveLeads
};
export default exportedObject;
