import fees from './fees';
import auth from './auth';
import users from './users';
import quotes from './quotes';
import exports from './export';
import forward from './forward';
import places from './places';
import dashboard from './dashboard';
import customers from './customers';
import currencies from './currencies';
import permissions from './permissions';
import agencies from './agencies';
import rates from './rates';
import leads from './leads';
import settings from './settings';
import pricelists from './pricelists';
import axios from 'axios';
import consts from './consts/consts';
import { authActions } from '../../store/auth';

axios.defaults.baseURL = consts.BACKEND_API_ENDPOINT;
const init = (dispatch) => {
    axios.interceptors.response.use(
        (res) => {
            return res;
        },
        (res) => {
            const { response } = res;
            if (response.data?.forceLogout) {
                dispatch(authActions.logout());
            }
            return Promise.reject(res);
        }
    );
};

const exportedObject = {
    init,
    auth,
    fees,
    users,
    quotes,
    exports,
    pricelists,
    forward,
    places,
    agencies,
    customers,
    dashboard,
    currencies,
    permissions,
    rates,
    leads,
    settings
};
export default exportedObject;
