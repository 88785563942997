import { configureStore } from '@reduxjs/toolkit';

import uiSlice from './ui';
import authSlice from './auth';
import quoteSlice from './quotes';
import draftEditSlice from './drafts/edit';
import menuReducer from '../mantis/store/reducers/menu';
import snackbarReducer from '../mantis/store/reducers/snackbar';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';

const persistConfig = {
    key: 'root',
    whitelist: ['editQuoteDrafts'],
    storage
};

const rootReducer = combineReducers({
    ui: uiSlice.reducer,
    auth: authSlice.reducer,
    quote: quoteSlice.reducer,
    editQuoteDrafts: draftEditSlice.reducer,
    snackbar: snackbarReducer,
    menu: menuReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.REACT_APP_ENV === 'local',
    middleware: [thunk]
});

export const persistor = persistStore(store);

export default store;
