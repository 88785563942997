import axios, { AxiosResponse } from 'axios';
import { IUser } from 'common/types/common';
import { createQuery } from 'lib/utils/api';
import { Period } from 'store/contexts/dashboard/consts';

interface DashboardOptions {
    startDate?: Date;
}

type DashboardReturnedType<T> = Promise<AxiosResponse<T>>;

export const getStatusSummaryByUserId = async () => {
    return await axios.get(`/dashboard/status-summary?by=userId`);
};

export const getPerDate = async () => {
    return await axios.get(`/dashboard/per-date-per-closed-group?by=userId`);
};

export const getLeadToCloseAvgByUserId = async () => {
    return await axios.get(`/dashboard/lead-to-close-avg?by=userId`);
};

export const getClosedPerLoggedInUser = async () => {
    return await axios.get(`/dashboard/closed-leads?me=true`);
};

export interface IGetPerUserOptions {
    user: IUser;
    data: {
        cbm: number;
        income: number;
        profit: number;
        teu: number;
        closedQuotes: {
            dataByReason: {
                [key: number]: {
                    count: number;
                    reason: { id: number; name: string };
                };
            };
        };
        leads: {
            dataByReason: {
                [key: number]: {
                    count: number;
                    reason: { id: number; name: string };
                };
            };
            avgHoursToClose: number;
            avgDaysToClose: number;
            totalLeads: number;
        };
    };
}
export const getPerUser = async ({ startDate }: DashboardOptions): DashboardReturnedType<IGetPerUserOptions[]> => {
    return await axios.get(`/dashboard/per-user?${createQuery(['startDate', startDate?.toISOString()])}`);
};

export const getSuccessFromCountries = async () => {
    return await axios.get(`/dashboard/success-from-countries`);
};

export const getFailedFromCountries = async () => {
    return await axios.get(`/dashboard/failed-from-countries`);
};

interface LeadStatus {
    count: number;
    avgHoursSinceCreated: number;
    avgDaysSinceCreated: number;
}

type ComparativeData = Record<Period, string> & { closedCount: number; notClosedCount: number };

export interface IGetAgencyData {
    leadsData: {
        leadsStatus: {
            closed: LeadStatus;
            notClosed: LeadStatus;
            total: LeadStatus;
        };
        comparativeData: ComparativeData[];
    };
    quotesData: {
        totalProfit: {
            usd: number;
        };
        totalIncome: {
            usd: number;
        };
        totalTeu: number;
        totalCbm: number;
    };
}

interface IGetAgencyDataOptions extends DashboardOptions {
    period: Period;
    numOfPeriods?: number;
}
export const getAgencyData = async ({ startDate, period, numOfPeriods }: IGetAgencyDataOptions): DashboardReturnedType<IGetAgencyData> => {
    return await axios.get(
        `/dashboard/agency?${createQuery(['startDate', startDate?.toISOString()], ['period', period], ['numOfIntervals', numOfPeriods])}`
    );
};

const exportedObject = {
    getPerDate,
    getPerUser,
    getFailedFromCountries,
    getSuccessFromCountries,
    getStatusSummaryByUserId,
    getLeadToCloseAvgByUserId,
    getAgencyData
};
export default exportedObject;
