import axios from 'axios';

export const getFeeTypes = async () => {
    return await axios.get(`/feeTypes`);
};

export const updateFCLFee = async ({ polId, podId, shippingCompanyId, containerTypeId, containerSizeId, isHazardous, date, contractRevisionId, price }) => {
    const data = {
        polId,
        podId,
        shippingCompanyId,
        containerTypeId,
        containerSizeId,
        isHazardous,
        price,
        date,
        contractRevisionId
    };
    return await axios.post(`/fees/fcl/frt`, { ...data });
};

export const updateLCLFee = async ({ polId, podId, shippingCompanyId, isHazardous, date, contractRevisionId, price }) => {
    const data = {
        polId,
        podId,
        shippingCompanyId,
        isHazardous,
        price,
        contractRevisionId,
        date
    };
    return await axios.post(`/fees/lcl/frt`, { ...data });
};

const exportedObject = {
    getFeeTypes,
    updateFCLFee,
    updateLCLFee
};
export default exportedObject;
