// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { permissions } from 'common/permissions-consts';
import { MenuItem } from './interface';
import RuleIcon from '@mui/icons-material/Rule';
import WidthFullOutlinedIcon from '@mui/icons-material/WidthFullOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

export const FCL_COSTS_ID = 'fcl-costs';
export const LCL_COSTS_ID = 'lcl-costs';
export const PRICELISTS_STATUS_ID = 'pricelists-status';

export const navPermissions = {
    [FCL_COSTS_ID]: [permissions.data.read, permissions.data.admin],
    [LCL_COSTS_ID]: [permissions.data.read, permissions.data.admin],
    [PRICELISTS_STATUS_ID]: [permissions.data.read, permissions.data.admin]
};

// icons
const icons = {
    RuleIcon
};

const applications: MenuItem = {
    id: 'pricelists',
    type: 'group',
    title: <FormattedMessage id="pricelists" />,
    children: [
        {
            id: FCL_COSTS_ID,
            title: <FormattedMessage id="fcl-costs" />,
            type: 'item',
            url: '/costs/fcl/freight',
            icon: WidthFullOutlinedIcon,
            requiredPermission: navPermissions?.[FCL_COSTS_ID],
            requiredFeatureFlag: 'costs.fcl'
        },
        {
            id: LCL_COSTS_ID,
            title: <FormattedMessage id="lcl-costs" />,
            type: 'item',
            url: '/costs/lcl/freight',
            icon: Inventory2OutlinedIcon,
            requiredPermission: navPermissions?.[LCL_COSTS_ID],
            requiredFeatureFlag: 'costs.lcl'
        },
        {
            id: PRICELISTS_STATUS_ID,
            title: <FormattedMessage id="pricelists-status" />,
            type: 'item',
            url: '/pricelists/status',
            icon: icons.RuleIcon,
            requiredPermission: navPermissions?.[PRICELISTS_STATUS_ID],
            requiredFeatureFlag: 'pricelistsStatus'
        }
    ]
};

export default applications;
