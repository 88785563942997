import axios from 'axios';

// quote, chargeGroups, optionalCharges will be used if the default format is pdf
export const forwardQuote = async (quoteId, attachmentName) => {
    // This is post because in the future we will add data to the payload
    return axios.post(`/forward/quote`, {
        quoteId,
        attachmentName
    });
};

const exportedObj = {
    forwardQuote
};

export default exportedObj;
