import { createAsyncThunk } from '@reduxjs/toolkit';

import config from '../../config';
import api from '../../lib/api-client/index';
import { authActions, LOCALSTORAGE_KEY } from './index';

export const postLogin = (userDetails, navigate) => {
    return async (dispatch) => {
        const postLogin = async () => {
            const response = await api.auth.login(userDetails);

            return response.data;
        };

        try {
            const loginData = await postLogin();

            if (loginData.status === 'success') {
                dispatch(authActions.login(loginData));
                navigate(config.defaultPath);
            } else {
                dispatch(authActions.setError({ message: 'Bad Request' }));
            }
        } catch (err) {
            if (err.response && err.response.status === 401) {
                dispatch(authActions.setError({ message: err.response.data.message }));
            } else {
                dispatch(authActions.setError({ message: 'Server Error' }));
            }
        }
    };
};

export const getToken = () => {
    return localStorage.getItem(LOCALSTORAGE_KEY) || null;
};

export const removeToken = () => {
    return localStorage.removeItem(LOCALSTORAGE_KEY);
};

export const fetchUserData = createAsyncThunk('auth/fetchUserData', async (_, { rejectWithValue }) => {
    try {
        const accessToken = getToken();
        const response = await api.auth.fetchUser();
        return { ...response.data, accessToken };
    } catch (e) {
        return rejectWithValue('');
    }
});
