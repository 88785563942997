// ==============================|| OVERRIDES - INPUT BASE ||============================== //

import useConfig from 'mantis/hooks/useConfig';

export default function InputBase() {
    const { fontSizeMultiplier } = useConfig();

    return {
        MuiInputBase: {
            styleOverrides: {
                sizeSmall: {
                    fontSize: `${0.75 * fontSizeMultiplier}rem`
                }
            }
        }
    };
}
