// ==============================|| OVERRIDES - TABLE PAGINATION ||============================== //

import useConfig from 'mantis/hooks/useConfig';

export default function TablePagination() {
    const { fontSizeMultiplier } = useConfig();
    return {
        MuiTablePagination: {
            styleOverrides: {
                selectLabel: {
                    fontSize: `${0.875 * fontSizeMultiplier}rem`
                },
                displayedRows: {
                    fontSize: `${0.875 * fontSizeMultiplier}rem`
                }
            }
        }
    };
}
