import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    action: false,
    open: false,
    message: 'Note archived',
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
    },
    variant: 'default',
    alert: {
        color: 'primary',
        variant: 'filled'
    },
    transition: 'Fade',
    close: true,
    actionButton: false
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const applyPayload = (state, payload) => {
    const { open, message, anchorOrigin, variant, alert, transition, close, actionButton } = payload;
    state.action = !state.action;
    state.open = open || initialState.open;
    state.message = message || initialState.message;
    state.anchorOrigin = anchorOrigin || initialState.anchorOrigin;
    state.variant = variant || initialState.variant;
    state.alert = {
        color: alert?.color || alert?.severity || initialState.alert.color,
        variant: alert?.variant || initialState.alert.variant,
        severity: alert?.severity
    };
    state.transition = transition || initialState.transition;
    state.close = close === false ? close : initialState.close;
    state.actionButton = actionButton || initialState.actionButton;
};

const snackbar = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        openSnackbar(state, { payload }) {
            applyPayload(state, payload);
        },
        openErrorSnackbar(state, { payload }) {
            applyPayload(state, {
                ...payload,
                variant: 'alert',
                alert: {
                    severity: 'error'
                },
                open: true,
                close: true
            });
        },
        openSuccessSnackbar(state, { payload }) {
            applyPayload(state, {
                ...payload,
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                open: true,
                close: true
            });
        },

        closeSnackbar(state) {
            state.open = false;
        }
    }
});

export default snackbar.reducer;

export const { closeSnackbar, openSnackbar, openErrorSnackbar, openSuccessSnackbar } = snackbar.actions;
