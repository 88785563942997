import axios from 'axios';

export const createCustomer = async (customerDetails) => {
    return await axios.post(`/customers`, customerDetails);
};

export const getCustomers = async ({ nameOrId, isActive }) => {
    const params = [];
    nameOrId?.length > 0 && params.push(`filter=${nameOrId}`);
    isActive && params.push(`isActive=true`);
    const url = `/customers${params.length > 0 ? `?${params.join('&')}` : ''}`;
    return await axios.get(url).then((res) => {
        return {
            ...res,
            data: res.data.map((customer) => ({
                ...customer,
                createdAt: new Date(customer.createdAt)
            }))
        };
    });
};

export const getCustomerById = async (custId) => {
    return await axios.get(`/customers/${custId}`);
};

export const getLatestIngestionTs = async () => {
    return await axios.get(`/customers/latest-ingestion-ts`);
};

const exportedObject = {
    getCustomers,
    createCustomer,
    getCustomerById,
    getLatestIngestionTs
};
export default exportedObject;
