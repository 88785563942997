import axios from 'axios';

export const getRoles = async () => {
    return await axios.get(`/permissions/roles`);
};

const exportedObject = {
    getRoles
};
export default exportedObject;
