import axios, { AxiosResponse } from 'axios';

interface IFetchedUser {
    agencyId: string;
    agencyName: string;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    password: string | null;
    permissions: {};
    phoneNumber: string;
    roleId: number;
    roleName: string;
    isActive: boolean;
}

type stringOrUndefined = string | undefined;

export const getAllByAgency = async (agencyId: stringOrUndefined, withSystemUsers: boolean = true) => {
    return await axios.get(`/agencies/${agencyId}/users?withSystemUsers=${withSystemUsers}`);
};

export const getUserById = async (userId: stringOrUndefined): Promise<AxiosResponse<IFetchedUser>> => {
    return await axios.get(`/users/${userId}`);
};

export const updateUser = async (userId: stringOrUndefined, data: any) => {
    return await axios.put(`/users/${userId}`, data);
};

const exportedObject = {
    getAllByAgency,
    getUserById,
    updateUser
};
export default exportedObject;
