import { createSlice } from '@reduxjs/toolkit';
import { LEADS, QUOTES } from 'lib/common/localStorage';
import { getLocalStorageKey } from 'hooks/useLocalStorage';
import axios from 'axios';

export const LOCALSTORAGE_KEY = 'token';

export const getToken = () => {
    return localStorage.getItem(LOCALSTORAGE_KEY) || null;
};

if (getToken()) {
    axios.defaults.headers.common['X-Access-Token'] = getToken();
}

const initialState = {
    user: {},
    error: {},
    isLoading: false,
    token: getToken()
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, { payload }) {
            localStorage.setItem(LOCALSTORAGE_KEY, payload.token);
            axios.defaults.headers.common['X-Access-Token'] = payload.token;
            state.user = payload;
            state.token = payload.token;
        },
        logout(state) {
            localStorage.removeItem(LOCALSTORAGE_KEY);
            delete axios.defaults.headers.common['X-Access-Token'];
            localStorage.removeItem(getLocalStorageKey(LEADS.MAIN_FILTERS_SETTINGS));
            localStorage.removeItem(getLocalStorageKey(QUOTES.MAIN_FILTERS_SETTINGS));
            sessionStorage.clear();
            state.token = null;
        },
        setUser(state, { payload }) {
            state.user = payload;
        },
        setError(state, { payload }) {
            state.error = payload;
        },
        setIsLoading(state, { payload }) {
            state.isLoading = payload;
        }
    }
});

export const authActions = authSlice.actions;

export default authSlice;
